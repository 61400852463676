import React from 'react';
import { FaRegCheckCircle } from 'react-icons/fa';
import './style.css';
import BuyNowButton from '../../components/BuyNowButton';

const benefitTexts = [
    'Improve your English fluency and skills.',
    'Sharpen your spoken English skills.',
    'Build confidence in communicating with native English speakers.',
    'Enrich your vocabulary and acquire new idioms and expressions.',
    'Cultivate a natural-sounding pronunciation and improved pronunciation.',
    'Deepen your understanding of English grammar and sentence structure.',
    'Master effective strategies for engaging in conversation and discussion.',
    'Overcome common challenges faced in spoken English.',
    'Access valuable resources and exercises to improve your speaking skills.',
];

const Benefit = () => {
    return (
        <div className='total-benefit-background'>
            <div className='buy-now-button-with-text'>
                <p className='text'>Click on this button to get the spoken English bundle now!</p>
                <BuyNowButton />
            </div>
            <div className='bundil-wrapper'>
                <div className='bundil-benefits'>
                    <p className='header-text'>Benefits to get this Ebook bundle</p>
                    <div className='benefits-container'>
                        {benefitTexts.map((text, index) => (
                            <div key={index} className='icon-with-benefit'>
                                <FaRegCheckCircle className='benefit-icon-size' />
                                <p className='benefit-text'>{text}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='buy-now-button-with-text-another'>
                <p className='regret-text'>Take action now, Don’t regret again.<span> Buy it now</span></p>
                <BuyNowButton />
            </div>
        </div>
    );
}

export default Benefit;
