import React from 'react';
import './style.css';

const Claim = () => {

    const normalplan = "https://dashboard.razorpay.com/app/paymentpages/pl_NRlQPYu4TbrLJt/success"

    const handleclick = () => {
        window.location.assign(normalplan)
    }
    return (
        <div className='total-claim-container'>
            <div className='claim-container-content'>
                <div className='header-text'>
                    <p className='first-text'>Actual ebook value: Rs.999/-</p>
                    <p className='second-text'>Only today you pay: Rs.99/-</p>
                </div>
                <div className='claim-container' onClick={handleclick}>
                    Claim your bundle today
                </div>
            </div>
        </div>
    )
}

export default Claim
