import React from 'react';
import "./style.css"
import FeatureCard from '../FeatureCard';

const Card = ({ img, title, description, offerPrice, realPrice, plan }) => {
    const newplan = () => {
        window.location.assign(plan);
    }

    return (
        <div className={"best-plan-card"}>
            <div className="card-img">
                <img src={img} alt="ebook" />
            </div>

            <div className="card-body">
                <div className={"card-body-test"}>

                    <div className={"card-text"}>
                        <div className={"card-title"}>{title}</div>
                        <div className="card-desc">{description}</div>
                    </div>
                    <div className="card-box" onClick={newplan}>
                        <FeatureCard offerPrice={offerPrice} realPrice={realPrice} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;