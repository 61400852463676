// import { useEffect, useState } from 'react';
// import './App.css';
// import { AppProvider } from './Hooks/apiContext';
// import Alert from './components/Alert';
// import Router from './router';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// function App() {
//   useEffect(() => {
//     const notifications = [
//       {
//         text: 'Jayashree in Katni India, purchased a',
//         title: 'English Spoken + Video Course',
//         timestamp: 'About 1 hour ago',
//       },
//       {
//         text: 'Joyti in New York, purchased a',
//         title: 'Programming Course',
//         timestamp: 'About 2 hours ago',
//       },
//       {
//         text: 'Pawan in Katni India, purchased a',
//         title: 'English Spoken + Video Course',
//         timestamp: 'About 5 hour ago',
//       },
//       {
//         text: 'Rabin in New York, purchased a',
//         title: 'Programming Course',
//         timestamp: 'About 7 hours ago',
//       },
//       {
//         text: 'Aditya in New York, purchased a',
//         title: 'Programming Course',
//         timestamp: 'About 7 hours ago',
//       },
//     ];

//     let lastShownIndex = 0;

//     const showTost = () => {
//       lastShownIndex = (lastShownIndex + 1) % notifications.length;
//       const currentNotification = notifications[lastShownIndex];

//       toast(<Alert notification={currentNotification} />, {
//         position: 'bottom-left',
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: false,
//         draggable: true,
//         progress: undefined,
//         theme: 'light',
//         limit: 1,
//         // pauseOnFocusLoss: false
//       });
//     };

//     setInterval(() => {
//       showTost();
//     }, 10000);

//     showTost();
//   }, []);

//   return (
//     <>
//       <AppProvider>
//         <Router />
//         <ToastContainer pauseOnFocusLoss={false} />
//       </AppProvider>
//     </>
//   );
// }

// export default App;






import { useEffect, useState } from 'react';
import './App.css';
import { AppProvider } from './Hooks/apiContext';
import Alert from './components/Alert';
import Router from './router';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  const notifications = [
    {
      text: 'Jayashree in Katni India, purchased a',
      title: 'English Spoken + Video Course',
      timestamp: 'About 1 hour ago',
    },
    {
      text: 'Joyti in New York, purchased a',
      title: 'English Spoken + Video Course',
      timestamp: 'About 2 hours ago',
    },
    {
      text: 'Pawan in Katni India, purchased a',
      title: 'English Spoken + Video Course',
      timestamp: 'About 5 hour ago',
    },
    {
      text: 'Rabin in New York, purchased a',
      title: 'English Spoken + Video Course',
      timestamp: 'About 9 hours ago',
    },
    {
      text: 'Aditya in New York, purchased a',
      title: 'English Spoken + Video Course',
      timestamp: 'About 12 hours ago',
    },
  ];


  useEffect(() => {
    let id;
    let lastShownIndex = 0;
    const showToast = () => {
      lastShownIndex = (lastShownIndex + 1) % notifications.length;
      const currentNotification = notifications[lastShownIndex];

      toast(<Alert notification={currentNotification} />, {
        position: 'bottom-left',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    };
    const startInterval = () => {
      id = setInterval(showToast, 4000);
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {

        clearInterval(id);
      } else {

        startInterval();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    startInterval();


    return () => {
      clearInterval(id);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <>
      <AppProvider>
        <Router />
        <ToastContainer />
      </AppProvider>
    </>
  );
}

export default App;