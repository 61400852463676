// import React from 'react';
// import './style.css'

// const Clock = () => {
//     return (
//         <div className='total-clock-background'>
//             <div className='clock-container-content'>
//                 <div className='clock-text'>
//                     <p>offer closed once time running out.<span className='span-text'> hurry up!</span></p>
//                 </div>
//                 <div className='real-time-container'>
//                     <div className='clock-content'>
//                         <p className='number-text'>19</p>
//                         <p className='value-text'>hours</p>
//                     </div>
//                     <div className='clock-content'>
//                         <p className='number-text'>59</p>
//                         <p className='value-text'>minutes</p>
//                     </div>
//                     <div className='clock-content'>
//                         <p className='number-text'>59</p>
//                         <p className='value-text'>seconds</p>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Clock


import React, { useState, useEffect } from 'react';
import './style.css';

const Clock = () => {
    const calculateTimeLeft = () => {
        const now = new Date();
        let deadline = new Date("2030-01-26T00:00:00");

        if (now > deadline) {
            deadline.setDate(deadline.getDate() + 1);
        }

        const difference = deadline - now;
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / (1000 * 60)) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };


    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft]);

    return (
        <div className='total-clock-background'>
            <div className='clock-container-content'>
                <div className='clock-text'>
                    <p>
                        offer closed once time running out.
                        <span className='span-text'> hurry up!</span>
                    </p>
                </div>
                <div className='real-time-container'>
                    <div className='clock-content'>
                        <p className='number-text'>{timeLeft.hours}</p>
                        <p className='value-text'>hours</p>
                    </div>
                    <div className='clock-content'>
                        <p className='number-text'>{timeLeft.minutes}</p>
                        <p className='value-text'>minutes</p>
                    </div>
                    <div className='clock-content'>
                        <p className='number-text'>{timeLeft.seconds}</p>
                        <p className='value-text'>seconds</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Clock;
