// import React from 'react'
// import './style.css'

// const Alert = () => {

//     const notifications = [
//         {
//             text: 'Shubham in Katni India, purchased a',
//             title: 'English Spoken + Video Course',
//             timestamp: 'About 1 hour ago',
//         },
//         {
//             text: 'Shubham in Katni India, purchased a',
//             title: 'English Spoken + Video Course',
//             timestamp: 'About 1 hour ago',
//         },
//     ];

//     return (
//         <>
//             {notifications.map((notification, index) => (
//                 <div className='notification' key={index}>
//                     <img src='/images/wait-book.png' className='notification-image' />
//                     <div className='text-container'>
//                         <p>{notification.text}</p>
//                         <h1>{notification.title}</h1>
//                         <h2>{notification.timestamp}</h2>
//                     </div>
//                 </div>
//             ))}
//         </>
//     )
// }

// export default Alert


import React from 'react';
import './style.css';

const Alert = ({ notification }) => {
    return (
        <div className='notification'>
            <img src='/images/wait-book.png' className='notification-image' />
            <div className='text-container'>
                <p>{notification.text}</p>
                <h1>{notification.title}</h1>
                <h2>{notification.timestamp}</h2>
            </div>
        </div>
    );
};

export default Alert;