import React from 'react';
import './style.css'

const SeventeenBooks = () => {
    return (
        <div className='total-seventeen-books-background'>
            <div className='books-container-with-text'>
                <div className='books-text'>
                    <p className='first-text'>17+ spoken</p>
                    <p className='second-text'>e-books</p>
                </div>
                <div>
                    <img src='/images/HeaderImages/Ebook_2nd-images.png' alt='seventeen' className='image-style' />
                </div>
            </div>
        </div>
    )
}

export default SeventeenBooks
