import React from 'react';
import './style.css'
import BuyNowButton from '../../components/BuyNowButton';

const BundilsFor = () => {
    return (
        <div className='total-bundils-for-background'>
            <div className='bundils-for-total-content'>
                <div className='three-layer-text'>
                    <h1 className='first-bundil-text'>Who Is This Bundle For?</h1>
                    <p className='second-bundil-text'>For individuals aspiring to enhance their spoken English and communication abilities!</p>
                    <p className='third-bundil-text'>Click on this button to get spoken english bundle now!</p>
                </div>
                <BuyNowButton />
            </div>
        </div>
    )
}

export default BundilsFor
