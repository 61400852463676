import React from 'react';

const List = ({ text }) => {
    return (
        <div className={"single-list"}>
            <img src="/images/check.svg" alt="check" className='image-size' />
            <p>{text}</p>
        </div>
    );
};

export default List;