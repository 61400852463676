import React, { useState } from 'react';
import "./style.css"
import { MdKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";

const QnaList = ({ question, ans, index, currentIndex, setCurrentIndex }) => {

    const isShow = index === currentIndex;
    return (
        <div className={"qna-list"} onClick={() => {
            setCurrentIndex(isShow ? null : index);
        }}>
            <div className="question">
                <div className="icon"> {isShow ? <MdOutlineKeyboardArrowUp size={30} /> :
                    <MdKeyboardArrowDown size={30} />} </div>
                {question}
            </div>
            {isShow &&

                <div className="ans show-ans">
                    <div className="icon"><MdKeyboardArrowDown size={30} /></div>
                    {ans}
                </div>
            }

        </div>
    );
};

export default QnaList;