import React, { useState } from 'react';
import "./style.css"
import QnaList from '../../components/QnaList/index.js';

const qnaData = [
    {
        question: "What is included in the Spoken English eBook Bundle?",
        ans: "The Spoken English eBook bundle covers a range of topics, covering grammar, vocabulary, pronunciation, idioms, conversational skills and more. It contains 20 comprehensive books designed to guide students from elementary to advanced English proficiency.",
    },
    {
        question: "WHO CAN BENEFIT FROM THIS EBOOK BUNDLE?",
        ans: "This eBook bundle is designed for individuals of varying skill levels, from beginners to advanced learners. Whether you're looking to improve your vocabulary, improve grammar skills, or develop a natural-sounding pronunciation, the bundle caters to a wide audience.",
    },
    {
        question: "Are eBooks suitable for self-study or do I need a tutor?",
        ans: "eBooks are designed to be user-friendly, making them suitable for self-study. However, the effectiveness of the material is complemented by personal effort and practice. Although a teacher is not mandatory, students may seek additional guidance for a more interactive learning experience.",
    },
    {
        question: "How can the eBook bundle help me improve my spoken English?",
        ans: "The eBook bundle provides a comprehensive and structured approach to improving spoken English. It covers basic aspects such as grammar and vocabulary, as well as advanced topics such as accent reduction and effective communication techniques. Engaging exercises and practical examples facilitate active learning, contributing to improved spoken English skills.",
    },
    {
        question: "Can I use the eBook bundle on my smartphone or tablet?",
        ans: "Yes, the eBook bundle is designed to be versatile and accessible. You can use it on your smartphone, tablet or any electronic device that supports eBook format. This flexibility allows for learning on the go.",
    },
    {
        question: "Is there any support provided if i have questions or need clarification?",
        ans: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci assumenda consectetur culpa ducimus est, molestias praesentium voluptates.",
    },
    {
        question: "Can i share the ebook bundle with others?",
        ans: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci assumenda consectetur culpa ducimus est, molestias praesentium voluptates.",
    },
    {
        question: "Is there any assistance if I have any questions or need clarification?",
        ans: "Yes, support is available. If you have questions or need clarification about any content within the eBook bundle, you can contact Support. Details about support options will be provided at the time of purchase.",
    },
    {
        question: "Can I share the eBook bundle with others?",
        ans: "The eBook bundle is intended for personal use. Sharing or distribution of content is not permitted without proper authorization. If others are interested, they are encouraged to acquire their own copies to fully benefit from the material.",
    }, {
        question: "When will I receive the eBook after purchase?",
        ans: "After completing the purchase, you will have immediate access to the eBook bundle. The delivery process is designed to be prompt, allowing you to start your learning journey without delay.",
    },
    , {
        question: "What is the language of the spoken English video course?",
        ans: "The language used in spoken English video courses is Hindi and English. Curriculum content is designed to immerse students in an English-speaking environment, facilitating language acquisition and practical application.",
    },
    , {
        question: "What payment methods do you support?",
        ans: "The supported payment methods for purchasing the spoken English ebook bundle include major credit cards such as Visa, MasterCard, American Express, as well as popular digital payment options like PayPal or any UPI. These secure and widely-used payment methods provide flexibility for customers to complete their purchase transactions conveniently and with confidence.",
    },

]
const Qna = () => {
    const [currentIndex, setCurrentIndex] = useState(null);
    return (
        <div className={"qna-container"}>
            <div className={"qna"}>
                <div className={"qna-title"}>Frequently asked questions (FAQs)</div>
                <div className="all-qna">
                    {qnaData.map((ele, id) => (
                        <QnaList
                            ans={ele.ans}
                            question={ele.question}
                            index={id}
                            currentIndex={currentIndex}
                            setCurrentIndex={setCurrentIndex}
                            key={id}
                        />
                    ))}
                </div>
                <div className="qna-support">
                    <div className={"des"}>if you still have more questions,</div>
                    <div className={"email-id-text"}>please email us: <span>hey@matricula.co.in</span></div>
                </div>
            </div>

        </div>
    );
};

export default Qna;