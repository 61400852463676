import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ScrollTop from '../components/scrolltoTop'
import EbookHeaderComponent from '../pages/Ebook-header-container'
import SeventeenBooks from '../pages/Books'
import BundilsFor from '../pages/BundilsValue'
import GridBooks from '../pages/GridBooks'
import Clock from '../pages/Clock'
import Benefit from '../pages/Benefit'
import WaitAdd from '../pages/WaitAdd'
import CustomerReview from '../pages/CustomerReview'
import Claim from '../pages/Claim'
import Achievement from '../pages/AchieveSection'
import Qna from '../pages/Qna'
import BestPlan from '../pages/BestPlan'
import EbookFooter from '../pages/Footer'

const Router = () => {
    return (
        <BrowserRouter>
            <ScrollTop />
            <Routes>
                <Route index element={<EbookHeaderComponent />} />
                {/* <Route index element={<EbookFooter />} /> */}
                {/* <Route index element={<BestPlan />} /> */}
                {/* <Route index element={<Qna />} /> */}
                {/* <Route index element={<WaitAdd />} /> */}
                {/* <Route index element={<CustomerReview />} /> */}
                {/* <Route index element={<Achievement />} /> */}
                {/* <Route index element={<Claim />} /> */}
                {/* <Route index element={<Clock />} /> */}
                {/* <Route index element={<Benefit />} /> */}
                {/* <Route index element={<BundilsFor />} /> */}
                {/* <Route index element={<GridBooks />} /> */}
                {/* <Route index element={<SeventeenBooks />} /> */}
            </Routes>
        </BrowserRouter>
    )
}

export default Router
