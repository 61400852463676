import React from 'react'
import './style.css'
import { FaLinkedin, FaTwitter } from "react-icons/fa";
import { RiInstagramLine } from "react-icons/ri";

const EbookFooter = () => {
    return (
        <div className='total-ebook-footer'>
            <div className='footer-content'>
                <div>
                    <img src='/images/matricula-logo-footer.png' alt='footer-logo' className='footer-image-ebook' />
                </div>
                <div className='about-container'>
                    <p className='header-text'>About Purbali Chatterjee</p>
                    <p className='down-text'>Purbali Chatterjee is an accomplished entrepreneur, educator, content creator, public speaker, and mentor hailing from India.</p>
                </div>
                <div className='popular-courses-container'>
                    <p className='header-text'>Popular Courses</p>
                    <p className='down-text'><a href='https://spokenenglish.matricula.co.in/'>The Complete Spoken English Guide</a></p>
                </div>
                <div className='get-touch-container'>
                    <p className='header-text'>Get in touch</p>
                    {/* <div className='all-icons-footer'>
                        <div className='social-icon-with-text'>
                            <FaTwitter className='icon-size' />
                            <p>Twitter</p>
                        </div>
                        <div className='social-icon-with-text'>
                            <FaLinkedin className='icon-size' />
                            <p>LinkedIn</p>
                        </div>
                        <div className='social-icon-with-text'>
                            <RiInstagramLine className='icon-size' />
                            <p>Instagram</p>
                        </div>
                    </div> */}
                    <div className='all-icons-footer'>
                        <div className='social-icon-with-text'>

                            <FaTwitter className='icon-size' />
                            <p><a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fpurbalicha2023">Twitter</a></p>
                        </div>
                        <div className='social-icon-with-text'>

                            <FaLinkedin className='icon-size' />
                            <p><a href="https://www.linkedin.com/in/purbali-chatterjee-8388a499/?originalSubdomain=in">LinkedIn</a></p>
                        </div>
                        <div className='social-icon-with-text'>
                            <RiInstagramLine className='icon-size' />
                            <p><a href="https://www.instagram.com/learn_with_purbali/">Instagram</a></p>
                        </div>
                    </div>

                </div>
            </div >
        </div >
    )
}

export default EbookFooter
