import React from 'react'
import './style.css';
import { FaRegCheckCircle } from "react-icons/fa";
import BuyNowButton from '../../components/BuyNowButton';
import SeventeenBooks from '../Books';
import BundilsFor from '../BundilsValue';
import GridBooks from '../GridBooks';
import Clock from '../Clock';
import Benefit from '../Benefit';
import WaitAdd from '../WaitAdd';
import CustomerReview from '../CustomerReview';
import Claim from '../Claim';
import Achievement from '../AchieveSection';
import Qna from '../Qna';
import BestPlan from '../BestPlan';
import { useGlobalHook } from "../../Hooks/apiContext"
import EbookFooter from '../Footer';

const EbookHeaderComponent = () => {
    const { titleChange } = useGlobalHook();
    titleChange("Ebook");
    return (
        <>

            <div className='n-ebook-background'>
                <div className='header-details-total-wrapper'>
                    <div className='header-content-content'>
                        <div className='header-text-wrapper'>
                            <p className='header-text'>
                                Elevate your <span className='span-2'>speaking proficiency</span> with a bundle of 17 <span className='span-1'>spoken English e-books</span> all for just <span className='span-1'>Rs. 99/-</span>
                            </p>
                            <p className='header-second-text'>
                                Offer valid next  24 hours, Get it now
                            </p>
                            <div className="mobile-view" >
                                <BuyNowButton />
                            </div>
                        </div>
                        <div className='image-position-set'>
                            <img src='/images/HeaderImages/Ebook_first-images.png' alt='image' className='header-book-image' />
                            <img src='/images/HeaderImages/Ebook_first-images-mobile.png' alt='image' className='header-book-image-mobile' />
                        </div>
                    </div>
                    <div className='total-header-container-button-wrapper'>
                        <div className='wrapping-with-text-wrapper'>
                            <div className='rating-with-users'>
                                <div className='first-container'>
                                    <img src='/images/Vectorimage.png' alt='vector-image' className='vector-image' />
                                    <div className='insider-text'>
                                        <p className='first-text'>25000+</p>
                                        <p className='second-text'>People benefited from this</p>
                                    </div>
                                </div>
                                <div className='first-container'>
                                    <img src="/images/star-comment_svgrepo.com.png" alt='image' className='vector-image' />
                                    <div className='insider-text'>
                                        <p className='first-text'>9.52/10</p>
                                        <p className='second-text'>Average Ratings</p>
                                    </div>
                                </div>
                            </div>
                            <div className='impact-texts-container'>
                                <div className='details-text-container'>
                                    <FaRegCheckCircle className='icon-style' />
                                    <p className='details-text'>People get amazing results..</p>
                                </div>
                                <div className='details-text-container'>
                                    <FaRegCheckCircle className='icon-style' />
                                    <p className='details-text'>Only positive impacts</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className='buy-now-button'>
                            <p className='value-text'><span className='normal-price'>buy now for Rs. 99/-</span> <span className='rack-price'> Rs. 999/-</span></p>
                            <p className='first-text'>“For first 100 people only!”</p>
                        </div> */}
                        <div className="desktop-view" >
                            <BuyNowButton />
                        </div>
                    </div>
                </div>
            </div>
            <SeventeenBooks />
            <BundilsFor />
            <GridBooks />
            <Clock />
            <Benefit />
            <BestPlan />
            <WaitAdd />
            <CustomerReview />
            <Claim />
            <Qna />
            <Achievement />
            <EbookFooter />
        </>
    )
}

export default EbookHeaderComponent
