// import React from 'react';
// import './style.css'

// const BuyNowButton = () => {

//     const basicUrl = "https://dashboard.razorpay.com/app/paymentpages/pl_NRlQPYu4TbrLJt/success";
//     const handleUrl = () => {
//         window.location.assign(basicUrl);
//     }

//     return (
//         <div className='buy-now-button' onClick={handleUrl}>
//             <p className='value-text'><span className='normal-price'>buy now for Rs. 99/-</span> <span className='rack-price'> Rs. 999/-</span></p>
//             <p className='first-text'>“For first 100 people only!”</p>
//         </div>
//     )
// }

// export default BuyNowButton


import React, { useState, useEffect } from 'react';
import './style.css';

const BuyNowButton = () => {
    const [shake, setShake] = useState(false);
    const basicUrl = "https://dashboard.razorpay.com/app/paymentpages/pl_NRlQPYu4TbrLJt/success";

    const handleUrl = () => {
        window.location.assign(basicUrl);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setShake(true);
            setTimeout(() => {
                setShake(false);
            }, 2000);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className={`buy-now-button ${shake ? 'shake' : ''}`} onClick={handleUrl}>
            <p className='value-text'>
                <span className='normal-price'>buy now for Rs. 99/-</span>
                <span className='rack-price'> Rs. 999/-</span>
            </p>
            <p className='first-text'>“For first 100 people only!”</p>
        </div>
    );
}

export default BuyNowButton;
